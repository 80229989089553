<template>
  <div>
    <div
      data-v-d9c6fd66
      class="el-dialog__wrapper"
      style="z-index: 2023; display: none"
    >
      <div
        role="dialog"
        aria-modal="true"
        aria-label="Add New Group"
        class="el-dialog"
        :style="
          getIsMobile
            ? 'margin-top: 15vh; width: 100%'
            : 'margin-top: 15vh; width: 30%'
        "
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">Add New Group</span>
          <button type="button" aria-label="Close" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="el-dialog__body"></div>
      </div>
    </div>

    <el-row :type="fromReceipentsPage ? '' : 'flex'" justify="center">
      <el-col
        v-if="!fromReceipentsPage"
        :xl="{ span: 2 }"
        :lg="{ span: 2 }"
        :md="{ span: 4 }"
        :sm="{ span: 6 }"
        :xs="{ span: 6 }"
      >
        <el-button v-if="!fromReceipentsPage" @click="goBack">
          <i class="el-icon-back">{{ getIsMobile ? "" : "Back" }}</i>
        </el-button>
      </el-col>
      <el-col
        :xl="{ span: fromReceipentsPage ? 24 : 12 }"
        :lg="{ span: fromReceipentsPage ? 24 : 12 }"
        :md="{ span: 20 }"
        :sm="{ span: 24 }"
        :xs="{ span: 24 }"
      >
        <div
          v-loading="loading"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="border">
            <h3
              class="
                bg-light
                text-center
                fw-medium
                blue-dark
                fs-5
                py-3
                px-4
                mb-0
                text-uppercase
              "
            >
              {{ getTitle }}
            </h3>
            <el-divider class="m-0"></el-divider>
            <dialog-component              
              title="Warning"
              :visible.sync="dialogVisible"
              :width="getIsMobile ? '100%' : '27%'"
            >
              <span>
                <h5>It looks like you have been editing something...</h5>
              </span>
              <span
                >If you leave before updating, your changes will be lost.</span
              >
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="backToContacts"
                  >Confirm</el-button
                >
              </span>
            </dialog-component>
            <el-form :model="contactFormData" class="py-3 px-4">
              <!-- {{ contactFormData }} -->
              <el-row :gutter="30">
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    First Name
                    <span class="text-danger">*</span>
                    </p>
                  <el-form-item
                    class="mb-05"
                    prop="first_name"
                    :required="true"
                  >
                    <el-input v-model="contactFormData.first_name"></el-input>
                    <p
                      class="error"
                      v-if="
                        getContactErrors &&
                        getContactErrors.first_name &&
                        getContactErrors.first_name != 'First Name is Required!'
                      "
                    >
                      {{ getContactErrors.first_name }}
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Last Name
                    <span class="text-danger">*</span>
                  </p>
                  <el-form-item
                    class="mb-05"
                    prop="last_name"
                    :required="true"
                  >
                    <el-input v-model="contactFormData.last_name"></el-input>
                    <p
                      class="error"
                      v-if="
                        getContactErrors &&
                        getContactErrors.last_name &&
                        getContactErrors.last_name != 'Last Name is Required!'
                      "
                    >
                      {{ getContactErrors.last_name }}
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Email
                    <span class="text-danger">*</span>
                    </p>
                  <el-form-item
                    class="mb-05"
                    prop="email"
                    :required="true"
                  >
                    <el-input v-model="contactFormData.email"></el-input>
                    <p
                      class="error"
                      v-if="
                        getContactErrors &&
                        getContactErrors.email &&
                        getContactErrors.email != 'Email is Required!'
                      "
                    >
                      {{ getContactErrors.email }}
                    </p>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.critical_error"
                    >
                      {{ getContactErrors.critical_error }}
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Middle Name
                    </p>
                  <el-form-item
                    class="mb-05"
                    prop="middle_name"
                  >
                    <el-input v-model="contactFormData.middle_name"></el-input>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.middle_name"
                    >
                      {{ getContactErrors.middle_name }}
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="30">
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <el-form-item class="mb-05" label="Title" prop="title">
                    <el-input v-model="contactFormData.title"></el-input>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.title"
                    >
                      {{ getContactErrors.title }}
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>-->
              <!----------------------------------------------------->
              <!-- <el-row
                v-if="getIsMobile"
                :gutter="30"
                class="add-new-contact-type-wrapper"
              >
                <el-col :span="12">
                  <el-form-item
                    class="mb-05"
                    label="Contact Type"
                    prop="contact_type"
                    :required="true"
                  >
                    <el-select
                      v-model="contactFormData.contact_type"
                      filterable
                      default-first-option
                      class="w-100"
                      value-key="entity"
                      @change="getEntityInfo($event, 'entity')"
                    >
                      <el-option
                        v-for="(type, index) in getAllContactTypes"
                        :key="index"
                        :value="type.name"
                        :label="type.name"
                        >{{ type.name }}</el-option
                      >
                    </el-select>
                    <p
                      class="error"
                      v-if="
                        getContactErrors &&
                        getContactErrors.contact_type &&
                        getContactErrors.contact_type !=
                          'Contact Type is Required!'
                      "
                    >
                      {{ getContactErrors.contact_type }}
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="primary"
                    class="mt-38 add-new-contact-type"
                    @click="showAddGroupDialog = true"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>{{ getIsMobile ? "New" : "Add New" }}</span>
                  </el-button>
                </el-col>
              </el-row> -->

              <!----------------------------------------------------->
              <el-row :gutter="30">
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Phone
                    </p>
                  <el-form-item class="mb-05" prop="phone">
                    <el-input v-model="contactFormData.phone_number"></el-input>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.phone_number"
                    >
                      {{ getContactErrors.phone_number }}
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Title
                    </p>
                  <el-form-item class="mb-05" prop="title">
                    <el-input v-model="contactFormData.title" ></el-input> 
                    <!-- @input="validateTitle()" -->
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.title"
                    >
                    <!-- (/^\s/.test(contactFormData.title) || /[!@#$%^&*(),.?:{}|<>]/.test(contactFormData.title)) -->
                      {{ getContactErrors.title }}
                    </p>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="2" class="mt-40">OR</el-col> -->
              </el-row>
              <el-row :gutter="30">
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Address
                    </p>
                  <el-form-item class="mb-05"  prop="address">
                    <el-input
                      type="textarea"
                      v-model="contactFormData.address"
                    ></el-input>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.address"
                    >
                      {{ getContactErrors.address }}
                    </p>
                  </el-form-item>
                </el-col>
                <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
                  <p class="for-name">
                    Company Name
                    </p>
                  <el-form-item
                    class="mb-05"
                    prop="company_name"
                  >
                    <el-input
                      v-model="contactFormData.company_name"
                      placeholder="Company name"
                    ></el-input>
                    <p
                      class="error"
                      v-if="getContactErrors && getContactErrors.company_name"
                    >
                      {{ getContactErrors.company_name }}
                    </p>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row>
                <el-col :span="12" v-if="getIsMobile">
                  <el-button
                    v-if="
                      currentEntity.type != 'STANDARD' &&
                      currentEntity.slug != 'receiver' &&
                      contactFormData.contact_type
                    "
                    type="primary"
                    class="mt-38 add-new-contact-type"
                    @click="addPrimaryDataDialog = true"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                    <span>{{ getIsMobile ? "New" : "Add New" }}</span>
                  </el-button>
                </el-col>
              </el-row> -->
              <el-row>
                <el-col :span="22">
                  <div>
                   Contact Type 
                   <span class="text-danger">*</span>
                  </div>
                  <!-- <el-select
                      v-model="contactFormData.contact_type"
                      filterable
                      default-first-option
                      class="w-100"
                      value-key="entity"
                      @change="getEntityInfo($event, 'entity')"
                    >
                      <el-option
                        v-for="(type, index) in getAllContactTypes"
                        :key="index"
                        :value="type.name"
                        :label="type.name"
                      >{{ type.name }}</el-option>
                    </el-select> -->
                  <p
                    class="error"
                    v-if="
                      getContactErrors &&
                      getContactErrors.contact_type &&
                      getContactErrors.contact_type !=
                        'Contact Type is Required!'
                    "
                  >
                    {{ getContactErrors.contact_type }}
                  </p>
                </el-col>
                <el-col :span="2">
                  <el-tooltip
                    effect="dark"
                    content="Add contact Types"
                    placement="top-start"
                  >
                    <a
                      style="border: 0; float: right; cursor: pointer"
                      @click="openAddContactTypesModal"
                    >
                      <i
                        v-if="
                          this.getActiveWorkspace &&
                          this.getActiveWorkspace.plan_type &&
                          this.getActiveWorkspace.plan_type === 'BUSINESS'
                        "
                        class="el-icon-circle-plus-outline"
                        style="
                          font-weight: 800;
                          color: #f754a2;
                          font-family: inter;
                          margin-top: -20px;
                        "
                      ></i>
                    </a>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="contact-types-card">
                    <div
                      v-if="
                        contactFormData.contact_types &&
                        contactFormData.contact_types.length
                      "
                      class="contact-types-container"
                    >
                      <el-tag
                        v-for="(ty, i) in contactFormData.contact_types"
                        :key="i"
                        :type="checkIsBusiness(ty) ? 'success' : ''"
                        class="m-1"
                      >
                        {{ getEntityLabel(ty) }}
                      </el-tag>
                    </div>
                    <p style="padding-left: 10px; padding-top: 5px" v-else>
                      Click + to add Contact Types
                    </p>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="add-new-contact-type-wrapper">
                <!-- {{ currentEntity.entity_type }} -->
                <el-col
                  v-if="currentEntity.entity_type === 'BUSINESS'"
                  :span="
                    primaryFields &&
                    primaryFields.length &&
                    currentEntity.entity_type === 'BUSINESS'
                      ? 9
                      : 12
                  "
                >
                  <div style="display: flex">
                    <!-- {{ accountNames }} -->
                    <el-form-item
                      class="mb-05"
                      label="Account Name"
                      prop="account_data_id"
                      :required="true"
                    >
                      <el-select
                        autocomplete="on"
                        class="w-100"
                        placeholder="Search with Account"
                        suffix-icon="el-icon-search"
                        v-model="contactFormData.account_data_id"
                        clearable
                        filterable
                        default-first-option
                        ref="searchSelect"
                      >
                        <span v-if="accountNames">
                          <el-option
                            v-for="(data, index) in accountNames"
                            :key="index"
                            :value="data._id"
                            :label="getLabel(data)"
                            >{{ getLabel(data) }}</el-option
                          >
                        </span>
                      </el-select>
                      <p
                        class="error"
                        v-if="
                          getContactErrors && getContactErrors.account_data_id
                        "
                      >
                        {{ getContactErrors.account_data_id }}
                      </p>
                    </el-form-item>
                    <a
                      style="
                        border: 0;
                        cursor: pointer;
                        position: relative;
                        top: 10px;
                        right: 25px;
                      "
                      @click="addPrimaryDataDialog = true"
                      v-if="!getIsMobile"
                    >
                      <i
                        class="el-icon-circle-plus-outline"
                        style="
                          font-weight: 800;
                          color: #f754a2;
                          font-family: inter;
                        "
                      ></i>
                    </a>
                  </div>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="30"> </el-row> -->
              <el-dialog
                title="Add Contact Type"
                :visible.sync="showAddGroupDialog"
                class="add-contact-type-popup"
              >
                <el-form>
                  <div>
                    <el-form-item class="mb-10" label="Contact Type">
                      <el-form-item>
                        <el-input
                          v-model="contact_type"
                          clearable
                          placeholder="Enter contact type"
                        ></el-input>
                        <p
                          class="error"
                          v-if="
                            getContactTypeErrors && getContactTypeErrors.name
                          "
                        >
                          {{ getContactTypeErrors.name }}
                        </p>
                      </el-form-item>
                      <el-form-item class="mb-10" label="Entity Type">
                        <el-radio-group
                          v-model="entity_type"
                          size="mini"
                          fill="#323b6a"
                          style="margin-left: 0.5rem"
                        >
                          <!-- <el-radio-button
                            label="GENERAL"
                            @click.native="isAccount = false"
                          ></el-radio-button>-->
                          <!-- <el-radio-button
                            label="BUSINESS"
                            @click.native="isAccount = true"
                          ></el-radio-button>-->
                          <el-radio-button
                            label="INDIVIDUAL"
                            @click.native="isAccount = true"
                          ></el-radio-button>
                        </el-radio-group>
                      </el-form-item>
                      <hr />
                      <p
                        class="error"
                        v-if="
                          getContactTypeErrors &&
                          getContactTypeErrors.critical_error
                        "
                      >
                        {{ getContactTypeErrors.critical_error }}
                      </p>
                    </el-form-item>

                    <el-form-item class="text-center mt-20">
                      <el-button @click="resetContactTypeData()"
                        >Cancel</el-button
                      >
                      <el-button
                        type="primary"
                        @click="addContactType"
                        :disabled="!contact_type.trim()"
                        class="px-20"
                      >
                        <i class="el-icon-check"></i> Submit
                      </el-button>
                    </el-form-item>
                  </div>
                </el-form>
              </el-dialog>
              <div class="mt-4 mb-3 text-right action-button-wrapper">
                <el-row>
                  <el-col
                    :lg="{ span: 24 }"
                    :md="{ span: 24 }"
                    :xs="{ span: 24 }"
                    :sm="{ span: 24 }"
                    :xl="{ span: 24 }"
                  >
                    <!-- <el-popconfirm
                      title="Are you sure you want to cancel?"
                      @confirm="goToContactList()"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                    >
                      <el-button slot="reference" class="action-button" plain
                        >Can</el-button
                      > </el-popconfirm
                    >&nbsp;&nbsp;-->

                    <el-button
                      style
                      type="success"
                      class="mt-38 action-button"
                      @click="updateContact"
                      v-if="$route.params.contact_id || this.selectedContact"
                      :disabled="
                        loading ||
                        !contactFormData.first_name ||
                        !contactFormData.last_name ||
                        !contactFormData.email ||
                        checkAllContactTypes(contactFormData.contact_types)
                      "
                      >Update Contact</el-button
                    >
                    <!-- ||
                        (/\s/.test(contactFormData.title) || /[!@#$
                        %^&*(),.?:{}|<>]/.test(contactFormData.title)) -->
                    <el-button
                      type="success"
                      class="mt-30 save-contact-button action-button"
                      :disabled="
                        !contactFormData.first_name ||
                        !contactFormData.last_name ||
                        !contactFormData.email ||
                        checkAllContactTypes(contactFormData.contact_types) "
                      @click="addContact"
                      v-else
                      >Save Contact</el-button
                    >&nbsp;&nbsp;
                    <el-popconfirm
                      v-if="!this.selectedContact"
                      class="mr-1 save-contact-button action-button"
                      title="Are you sure you want to cancel?"
                      @confirm="backToContacts"
                      confirm-button-text="Yes"
                      cancel-button-text="No"
                    >
                      <el-button slot="reference" class="action-button" plain
                        >Cancel</el-button
                      >
                    </el-popconfirm>
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
    <AddContactTypes
      v-on:newContactTypeAdded="contactTypeDialogVisibible = false"
      v-if="contactTypeDialogVisibible"
    ></AddContactTypes>

    <!-- :before-close="addPrimaryDataDialog == false" -->
    <el-dialog
      title="Add Account"
      :visible.sync="addPrimaryDataDialog"
      :width="getIsMobile ? '100%' : '50%'"
    >
      <el-form ref="form" :model="form" class="sidemenu">
        <PrimaryDataForm
          :fieldsListData="primaryFields"
          :form="form"
          v-if="addPrimaryDataDialog"
        ></PrimaryDataForm>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="addPrimaryDataToEntity('form')"
          size="small"
        >
          <i class="el-icon-plus"></i> Add Account
        </el-button>
        <el-button @click="addPrimaryDataDialog = false" size="small"
          >Cancel</el-button
        >
      </span>
    </el-dialog>
    <dialog-component
      title="Add Contact Type"
      :visible="showContactTypes"
      :containerWidth="getIsMobile ? '100%' : '550px'"
      @before-close="showContactTypes=false"
    >
      <div v-loading="contactTypesModalLoading">
        <el-scrollbar wrap-style="max-height: 30vh;" ref="contactTypeScroll">
          <el-table :data="allContactTypes" border>
            <el-table-column
              prop="contact_type"
              label="Contact Type"
              width="180"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.contact_type"
                  placeholder="Contact type"
                  size="mini"
                  @change="
                    getAccountNameInfo([scope.row], checkIsBusiness(scope.row))
                  "
                >
                  <el-option
                    v-for="(type, index) in getAllContactTypes"
                    :key="index + '_table_' + type._id"
                    :value="type._id"
                    :label="type.name"
                    :disabled="checkExisted(type)"
                    >{{ type.name }}
                    <span style="float: right"
                      ><i
                        class="el-icon-user"
                        v-if="type.entity_type == 'INDIVIDUAL'"
                      ></i>
                      <i class="el-icon-star-on" v-else> </i
                    ></span>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="account_data_id"
              label="Account Name"
              width="180"
            >
              <template slot-scope="scope">
                <el-select
                  v-if="checkIsBusiness(scope.row)"
                  v-model="scope.row.account_data_id"
                  :disabled="!scope.row.contact_type"
                  placeholder="Account name"
                  size="mini"
                >
                  <el-option
                    v-for="(account, i) in accountNamesObj[
                      scope.row.contact_type
                    ]"
                    :key="i"
                    :value="account._id"
                    :label="getLabel(account)"
                  >
                    {{ getLabel(account) }}
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="has_login" label="Has logged in" width="180">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.has_login"> </el-checkbox>
                <el-link
                  type="danger"
                  :underline="false"
                  class="ml-3"
                  :disabled="allContactTypes.length == 1 || checkIsReceiver(scope.row.contact_type)"
                  @click="removeContactType(scope.$index)"
                  ><i class="el-icon-delete"></i
                ></el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
        <div class="float-right mt-1">
          <el-link
            icon="el-icon-circle-plus-outline"
            @click="addNewContactObj"
            type="primary"
            :underline="false"
            :style="getIsMobile ? { marginRight: '10px' } : ''"
            >Add</el-link
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showContactTypes = false">Cancel</el-button>
        <el-button
          type="success"
          :disabled="checkAllContactTypes(allContactTypes)"
          @click="addContactTypes"
          >Confirm
        </el-button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddContactTypes from "@/components/contacts/AddContactTypes";

export default {
  name: "contacts-AddContacts",
  props: ["fromReceipents", "contactData", "selectedContact", "showAddContact"],
  components: {
    AddContactTypes,
    PrimaryDataForm: () => import("../entity/PrimaryDataCollectionForm"),
  },
  data() {
    return {
      recipientSelectedContactType: "",
      selectedContactType: "",
      showAddGroupDialog: false,
      closeAddContactsPopup: this.showAddContact,
      dialogVisible: false,
      loading: false,
      loadingText: "Loading...",
      modalLoading: false,
      contactTypeDialogVisibible: false,
      contact_type: "",
      // getContactErrors: {},
      listOfCompanies: [],
      contactFormData: {
        first_name: "",
        last_name: "",
        middle_name: "",
        email: "",
        phone_number: "",
        address: "",
        company_name: "",
        contact_type: "",
        title: "",
        contactType: "",
        account_data_id: "",
        contact_types: [],
      },
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Invalid Email",
            trigger: "blur",
          },
        ],
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last name is required",
            trigger: "blur",
          },
        ],
      },
      addPrimaryDataDialog: false,
      primaryFields: [],
      form: {},
      entity_id: null,
      accountNames: [],
      currentEntity: {},
      entity_type: "INDIVIDUAL",
      isAccount: false,
      fromReceipentsPage: false,
      showContactTypes: false,
      allContactTypes: [],
      newContactType: {
        contact_type: "",
        account_data_id: "",
        has_login: true,
      },
      accountNamesObj: {},
      mappedEntityPrimaryFields: {},
      contactTypesModalLoading: false,
    };
  },
  async mounted() {
    this.recipientSelectedContactType =
      this.$route.params.selectedContact_typeId;
    this.selectedContactType = this.$route.params.contactTypeId;
    this.loading = true;
    this.$store.commit("contacts/setContactErrors", null, { root: true });
    const editContact = this.selectedContact || this.$route.params.contact_id;
    // console.log("editContact", editContact);
    await this.fetchAllContactTypesData();
    if (editContact) {
      this.loadingText = "Fetching Contact...";
      await this.$store.dispatch("contacts/fetchContact", editContact);
      this.setContact();
    }
    this.fromReceipentsPage = this.fromReceipents;
    if (this.fromReceipents && !this.selectedContact) {
      this.resetForm();
      // console.log("data", this.contactData);
      this.contactFormData.email = this.contactData.email;
      this.contactFormData.first_name = this.contactData.first_name;
      this.contactFormData.last_name = this.contactData.last_name;
      this.contactFormData.contact_type = this.contactData.contact_type;
      // this.getEntityInfo();
    }
    this.loading = false;
  },
  computed: {
    getTitle() {
      return this.$route.params.contact_id || this.selectedContact
        ? "Update Contact"
        : "Add Contact";
    },
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeCreateStatus",
      "getContactTypeErrors",
      "getAllAccountNames",
    ]),
    ...mapGetters("contacts", [
      "getCreateContactStatus",
      "getCreateContactData",
      "getContactErrors",
      "getUpdateContactStatus",
      "getContact",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("company", ["getListOfCompanies", "getCompanyDetails"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
    ]),

    ...mapGetters("entities", ["getEntityDataById"]),
  },
  methods: {
    checkIsReceiver(row) {
      let contactTypes = this.getAllContactTypes;
      return contactTypes.some(e => e._id === row && e.name === 'Receiver');
    },

  //   validateTitle() {
  //   const title = this.contactFormData.title;
  //   if (/\s/.test(title) || /[!@#$%^&*(),.?{}|<>]/.test(title)) {
  //     this.$set(this.getContactErrors, 'title', "Spaces and special characters are not allowed.");
  //   } else {
  //     this.$delete(this.getContactErrors, 'title');
  //   }
  // },
    checkAllContactTypes(allContactTypes) {
      if (!allContactTypes.length) {
        return true;
      } else if (allContactTypes.length) {
        let error = allContactTypes.find((e) => {
          if (
            !e.contact_type ||
            (this.checkIsBusiness(e) && !e.account_data_id)
          ) {
            return e;
          }
        });
        return error ? true : false;
      }
      return false;
    },
    addContactTypes() {
      if (this.allContactTypes.length) {
        this.contactFormData.contact_types = [...this.allContactTypes];
        this.allContactTypes = [];
        this.showContactTypes = false;
      } else {
        this.showContactTypes = true;
        this.$message({
          message: "Please add atleast one contact type",
          type: "warning",
        });
      }
    },
    getEntityLabel(contactType) {
      if (contactType?.contact_type) {
        let name = "";
        let selected = (this.getAllContactTypes || []).find(
          (e) => e._id == contactType?.contact_type
        );
        if (selected?.name) {
          name = selected?.name;
          if (
            contactType?.account_data_id &&
            this.accountNamesObj[contactType.contact_type]
          ) {
            let accountName = this.accountNamesObj[
              contactType.contact_type
            ].find((e) => e._id == contactType?.account_data_id);
            if (accountName) {
              name = name + "-" + this.getLabel(accountName);
            }
          }
          return name;
        }
      }
      return "";
    },
    checkExisted(ct) {
      return this.allContactTypes.find((e) => e.contact_type == ct._id)
        ? true
        : false;
    },
    removeContactType(index) {
      this.allContactTypes.splice(index, 1);
    },
    async getAccountNameInfo(data, isBusinessEntity) {
      console.log("3", data);
      if (data.length && isBusinessEntity) {
        this.contactTypesModalLoading = true;
        let params = {
          contact_types: data
            .filter((e) => e.contact_type)
            .map((e) => e.contact_type),
        };
        await this.$store.dispatch(
          "contactTypes/fetchEntityAccountList",
          params
        );
        if (this.getAllAccountNames) {
          this.accountNamesObj = {
            ...this.accountNamesObj,
            ...this.getAllAccountNames,
          };
        }
        this.contactTypesModalLoading = false;
      }
    },
    checkIsBusiness(data) {
      return (this.getAllContactTypes || []).find(
        (x) => x._id === data.contact_type && x.entity_type == "BUSINESS"
      )
        ? true
        : false;
    },
    addNewContactObj() {
      this.allContactTypes.push({
        ...JSON.parse(JSON.stringify(this.newContactType)),
      });
      let scrollbarEl = this.$refs.contactTypeScroll.wrap;
      scrollbarEl.scrollTop = scrollbarEl.scrollHeight;
    },
    openAddContactTypesModal() {
      this.allContactTypes = [];
      console.log(
        "this.contactFormData.contact_types",
        this.contactFormData.contact_types
      );
      this.allContactTypes = [...this.contactFormData.contact_types];
      this.showContactTypes = true;
    },
    resetForm() {
      this.contactFormData.first_name = "";
      this.contactFormData.last_name = "";
      this.contactFormData.email = "";
      this.contactFormData.title = "";
      this.contactFormData.phone_number = "";
      this.contactFormData.address = "";
      this.contactFormData.company_name = "";
      this.contactFormData.contact_type = "";
      this.contactFormData.account_data_id = "";
      this.contactFormData.contact_types = [];
    },
    getLabel(data) {
      if (
        data.entity_id &&
        data.entityData &&
        this.mappedEntityPrimaryFields[data.entity_id] &&
        this.mappedEntityPrimaryFields[data.entity_id].includes("#")
      ) {
        let [tempId, key] =
          this.mappedEntityPrimaryFields[data.entity_id].split("#");
        if (data.entityData[tempId] && data.entityData[tempId][key]) {
          return data.entityData[tempId][key + "/name"]
            ? data.entityData[tempId][key + "/name"]
            : data.entityData[tempId][key];
        } else if (data.entity_prime_data[key]) {
          return data.entity_prime_data[key];
        } else {
          return Object.keys(data.entity_prime_data) &&
            Object.keys(data.entity_prime_data)[0] &&
            data.entity_prime_data[Object.keys(data.entity_prime_data)[0]]
            ? data.entity_prime_data[Object.keys(data.entity_prime_data)[0]]
            : "";
        }
      }
    },
    getRequired() {
      return this.getEntityDataById.primaryFields.filter(
        (e) => this.form[e.key]
      ).length;
    },
    getTotalCount() {
      return this.getEntityDataById.primaryFields.filter((e) => e.key).length;
    },
    async addPrimaryDataToEntity() {
      try {
        if (this.form && this.getRequired() === this.getTotalCount()) {
          let data = {
            primaryFields: this.getEntityDataById.primaryFields,
            templates: this.getEntityDataById.templates,
            formData: this.form,
            entity_id: this.entity_id,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );

          if (this.getPrimaryEntityDataStatus) {
            this.addPrimaryDataDialog = false;
            this.$notify.success({
              title: "Success",
              message: "Account added successfully",
            });
            if (this.getNewEntityData) {
              this.loading = true;
              await this.$store.dispatch("company/fetchListOfCompanies", {
                contactTypeName: this.contactFormData.contact_type,
              });
              if (this.getListOfCompanies) {
                this.accountNames = this.getListOfCompanies;
                setTimeout(() => {
                  this.contactFormData.account_data_id =
                    this.getNewEntityData._id;
                  this.loading = false;
                }, 1000);
              } else {
                this.loading = false;
              }
              // bus.$emit("next-step", {
              //   newEntityData: this.getNewEntityData,
              //   firstStep: true,
              // });
            }
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at Creating Account",
            });
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getEntityInfo(e) {
      this.loading = true;
      if (e) {
        this.contactFormData.company_name = "";
      }
      let id = (this.getAllContactTypes || []).filter(
        (x) => x.name === this.contactFormData.contact_type
      );
      if (this.contactFormData.contact_type == "Receiver") {
        this.accountNames = [];
      } else {
        this.listOfCompanies = [];
        await this.$store.dispatch("company/fetchListOfCompanies", {
          contactTypeName: this.contactFormData.contact_type,
        });
        if (this.getListOfCompanies) {
          this.accountNames = this.getListOfCompanies;
        }
      }

      // console.log("Filtered EntityId", id);
      if (id && id.length) {
        this.currentEntity = id[0];
        this.entity_id = id[0]._id;
        this.contactFormData.contactType = this.currentEntity.entity_type;
        await this.$store.dispatch("entities/fetchEntityById", {
          entity_id: this.entity_id,
        });
        if (
          this.currentEntity &&
          this.currentEntity.templates &&
          this.currentEntity.entity_type == "BUSINESS" &&
          this.currentEntity.type != "STANDARD" &&
          this.currentEntity.slug != "receiver" &&
          !this.currentEntity.templates.length
        ) {
          this.contactFormData.contactType = "";
          this.contactFormData.contact_type = "";
          this.$message({
            message: "No templates included in this entity",
            type: "warning",
          });
        } else if (
          this.currentEntity &&
          this.currentEntity.templates &&
          this.currentEntity.entity_type == "BUSINESS" &&
          this.currentEntity.type != "STANDARD" &&
          this.currentEntity.slug != "receiver" &&
          !this.currentEntity.primaryFields.length &&
          !this.accountNames.length
        ) {
          this.contactFormData.contactType = "";
          this.contactFormData.contact_type = "";
          this.$message({
            message: "Primary fields not selected for this entity",
            type: "warning",
          });
        } else {
          if (e) {
            this.contactFormData.company_name = "";
            this.contactFormData.account_data_id = "";
          }
          this.contactFormData.entity_id = this.entity_id;
        }
      }
      if (this.currentEntity) {
        this.primaryFields = this.currentEntity.primaryFields;
      }

      setTimeout(() => {
        this.loading = false;
      });
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1500);
    },
    // fillrecipientEmailData(index, isFromBlur = false) {
    //   console.log(index);
    //   console.log(isFromBlur);
    // },
    // onEmailLostFocus(e) {
    //   this.contactFormData.company_name = e.target.value;
    //   // console.log(">>> onEmailLostFocus(), e", e);
    //   // console.log(">>> onEmailLostFocus(), index, this.users", index);
    //   // this.fillrecipientEmailData(index, true);
    // },
    // selectchange(e) {
    //   console.log(">>> selectchange(), e", e);
    // },
    // selectFocus(e) {
    //   // this.selectedUserIndex = index;
    //   console.log(e);
    // },
    // handleEmailFilter(filterVal) {
    //   // this.contactFormData.company_name = filterVal;
    //   console.log(filterVal);
    // },
    refreshDocument() {
      window.location.reload();
    },
    async addContactType() {
      try {
        this.modalLoading = true;
        await this.$store.dispatch("contactTypes/createContactType", {
          name: this.contact_type.trim(),
          description: "",
          entity_type: this.entity_type,
          isAccount: this.isAccount,
        });

        if (this.getContactTypeCreateStatus) {
          this.successNotify("Contact type created Successfully");
          this.$emit("newContactTypeAdded", true);
          this.showAddGroupDialog = false;
          this.modalLoading = false;
          this.fetchAllContactTypesData();
          this.contactFormData.contact_type = this.contact_type;
        }
        this.contact_type = "";
      } catch (err) {
        this.modalLoading = false;
        console.log(err);
      }
    },
    resetContactTypeData() {
      this.showAddGroupDialog = false;
      this.contact_type = "";
    },
    async updateContact() {
      try {
        this.loading = true;
        this.loadingText = "Updating Contact...";
        if (
          this.contactFormData.email.toLowerCase() ==
          this.getAuthenticatedUser.email.toLowerCase()
        ) {
          // this.$message()
          this.$message.warning("oops, You entered a Sender email");
          this.loading = false;
          return;
        }
        if (!this.contactFormData.account_data_id) {
          delete this.contactFormData.account_data_id;
        }
        // if (this.currentEntity.entity_type === "INDIVIDUAL" && this.currentEntity.type != 'STANDARD' && this.currentEntity.slug != 'receiver' ) {
        //   this.contactFormData.company_name =
        //     this.contactFormData.first_name +
        //     " " +
        //     this.contactFormData.last_name;
        // }
        await this.$store.dispatch("contacts/updateContact", {
          contact_id: this.$route.params.contact_id || this.selectedContact,
          contact: this.contactFormData,
        });
        this.loading = false;
        if (this.getUpdateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact updated successfully",
          });
          if (this.fromReceipentsPage) {
            this.contactFormData._id = this.selectedContact;
            this.$emit("close", {
              createContact: true,
              data: this.contactFormData,
              is_update: true,
              is_creating_new_contact: false,
            });
          } else {
            this.goToContactList();
          }
        } else if (this.getContactErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error in updating Contact",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchAllContactTypesData() {
      try {
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          from_contacts: true,
          get_all: true,
          type:'CONTACT_TYPE'
        });
        if (this.getAllContactTypes) {
          this.getAllContactTypes.forEach((e) => {
            this.mappedEntityPrimaryFields[e._id] =
              e.primaryFields &&
              e.primaryFields[0] &&
              e.primaryFields[0].key &&
              e.primaryFields[0].template_id
                ? e.primaryFields[0].template_id + "#" + e.primaryFields[0].key
                : "";
          });
          let recieverType = this.getAllContactTypes.find(
            (e) => e.type == "STANDARD" && e.slug == "receiver"
          );
          console.log("1111111111111", recieverType, this.$route.name);
          if (recieverType && this.$route.name == "add-contact") {
            this.contactFormData.contact_types.push({
              contact_type: recieverType._id,
              has_login: true,
            });
          }
        }
        if (this.getAllContactTypes.length == 1) {
          this.contactFormData.contact_type = this.getAllContactTypes[0].name;
        } else if (this.selectedContactType) {
          let contactType = this.getAllContactTypes.find(
            (e) => e._id == this.selectedContactType
          );
          if (contactType && contactType.name) {
            this.contactFormData.contact_type = contactType.name;
          }
        } else if (this.recipientSelectedContactType) {
          let recipientType = this.getAllContactTypes.find(
            (e) => e._id == this.recipientSelectedContactType
          );
          if (recipientType && recipientType.name) {
            this.contactFormData.contact_type = recipientType.name;
          }
        }
        // this.getEntityInfo();
      } catch (err) {
        console.log(err);
      }
    },
    async addContact() {
      try {
        this.loading = true;
        this.loadingText = "Adding Contact...";
        if (
          this.contactFormData.email.toLowerCase() ==
          this.getAuthenticatedUser.email.toLowerCase()
        ) {
          // this.$message()
          this.$message.warning("oops, You entered a Sender email");
          this.loading = false;
          return;
        }
        if (!this.contactFormData.account_data_id) {
          delete this.contactFormData.account_data_id;
        }
        if (!this.contactFormData.contact_types.length) {
          this.$message.warning("Please add atleast one contact type");
          this.loading = false;
          return;
        } else {
          this.contactFormData.contact_types =
            this.contactFormData.contact_types.map((e) => {
              if (e.contact_type) {
                let selected = (this.getAllContactTypes || []).find(
                  (e) => e._id == e.contact_type
                );
                if (selected && selected.type == "STANDARD") {
                  delete e.account_data_id;
                }
              }
              return e;
            });
        }
        if (this.contactFormData.contact_type) {
          delete this.contactFormData.contact_type;
        }
        await this.$store.dispatch(
          "contacts/createContact",
          this.contactFormData
        );
        this.loading = false;
        if (this.getCreateContactStatus) {
          this.$notify.success({
            title: "Success",
            message: "Contact created successfully",
          });
          if (this.fromReceipentsPage) {
            console.log("this.getCreateContactData", this.getCreateContactData);
            this.$emit("close", {
              createContact: true,
              data: this.getCreateContactData,
              is_update: false,
              is_creating_new_contact: true,
            });
          } else {
            //this.goToContactList();
            this.$router.go(-1);
          }
        } else if (this.getContactErrors && this.getContactErrors.length) {
          this.$notify.error({
            title: "Error",
            message: "Error in creating Contact",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    addcontacts() {
      this.$router.push({
        path: "/contacts",
      });
    },
    goToContactList() {
      if (this.fromReceipentsPage) {
        this.$emit("close", {
          createContact: false,
          data: {},
          is_update: false,
          is_creating_new_contact: false,
        });
      } else {
        this.$router.push({
          path: "/contacts",
        });
      }
    },

    setContact() {
      if (this.getContact) {
        this.contactFormData.first_name = this.getContact.first_name;
        this.contactFormData.last_name = this.getContact.last_name;
        this.contactFormData.email = this.getContact.email;
        this.contactFormData.phone_number = this.getContact.phone_number
          ? this.getContact.phone_number
          : "";
        this.contactFormData.middle_name = this.getContact.middle_name
          ? this.getContact.middle_name
          : "";
        this.contactFormData.address = this.getContact.address
          ? this.getContact.address
          : "";
        this.contactFormData.company_name = this.getContact.company_name
          ? this.getContact.company_name
          : "";
        this.contactFormData.account_data_id = this.getContact.account_data_id;
        this.contactFormData.contact_type =
          this.getContact.contact_type && this.getContact.contact_type._id
            ? this.getContact.contact_type._id
            : "";
        this.contactFormData.title = this.getContact.title;
        this.contactFormData.contact_types = this.mappedContactData(
          this.getContact
        );
        console.log("1", this.contactFormData.contact_types);
        this.getBusinessEntityData(this.contactFormData.contact_types);
        // this.getEntityInfo();
      }
    },
    getBusinessEntityData() {
      let bussinessEntities = this.contactFormData.contact_types.filter((e) =>
        this.checkIsBusiness(e)
      );
      console.log("2", bussinessEntities);
      this.getAccountNameInfo(bussinessEntities, true);
    },
    mappedContactData(data) {
      console.log("0", data);
      if (data.contact_types && data.contact_types.length) {
        return data.contact_types;
      } else if (data?.contact_type) {
        return [
          {
            contact_type: data.contact_type?._id
              ? data.contact_type._id
              : data.contact_type,
            account_data_id: data.account_data_id,
            has_login: true,
          },
        ];
      }
      return [];
    },
    //written by sekhar
    goBack() {
      if (
        (this.getContact &&
          this.getContact.first_name == this.contactFormData.first_name &&
          this.getContact.last_name == this.contactFormData.last_name &&
          this.getContact.middle_name == this.contactFormData.middle_name &&
          this.getContact.email == this.contactFormData.email &&
          this.getContact.title == this.contactFormData.title &&
          this.getContact.phone_number == this.contactFormData.phone_number &&
          this.getContact.address == this.contactFormData.address &&
          this.getContact.company_name == this.contactFormData.company_name) ||
        (this.contactFormData.first_name == "" &&
          this.contactFormData.last_name == "" &&
          this.contactFormData.email == "" &&
          this.contactFormData.title == "" &&
          this.contactFormData.phone_number == "" &&
          this.contactFormData.address == "" &&
          this.contactFormData.address == "" &&
          this.contactFormData.company_name == "")
      ) {
        (this.dialogVisible = false), this.$router.go(-1);
      } else {
        this.dialogVisible = true;
      }
    },
    backToContacts() {
      console.log(this.$route);
      if (
        this.$route &&
        this.$route.name &&
        (this.$route.name == "add-contact" ||
          this.$route.name == "edit-contact")
      ) {
        this.$router.go(-1);
      } else {
        this.$emit("close", {
          createContact: false,
          data: {},
          is_update: false,
        });
      }
    },
  },
  beforeDestroy() {
    // this.$store.commit(
    // "company/setCompanyDetails",null,{root:true})
    this.$store.commit("company/setListOfCompanies", null, { root: true });
    this.$store.commit("contacts/setCreateContactStatus", null, { root: true });
    this.$store.commit("contacts/setCreateContactData", null, { root: true });
    this.$store.commit("contacts/setContactErrors", null, { root: true });
    this.$store.commit("contacts/setUpdateContactStatus", null, { root: true });
    this.$store.commit("contacts/setContact", null, { root: true });
    // this.$store.commit("contactTypes/setAllContactTypes", null,{root:true})
    this.$store.commit("contactTypes/setContactTypeErrors", null, {
      root: true,
    });
    // this.$store.commit("contactTypes/setContactTypeCreateStatus", null,{root:true})
    this.$store.commit("configurableDocuments/setDocumentUsers", null, {
      root: true,
    });
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      null,
      { root: true }
    );
    // this.$store.commit("templatesData/setNewEntityData", null, { root: true });
    this.$store.commit(
      "templatesData/setPrimaryEntityDataStatus",
      null,

      {
        root: true,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.mt-38 {
  margin-top: 38px;
}
.mt-40 {
  margin-top: 40px;
}
.error {
  color: red;
}
</style>
<style lang="scss">
.sidemenu {
  height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
@media (max-width: 440px) {
  .add-new-contact-type-wrapper {
    & > div {
      &:first-child {
        width: 60% !important;
        padding-right: 0px !important;
      }
      &:last-child {
        width: 40% !important;
        padding-left: 10px !important;
      }
    }
    .add-new-contact-type {
      padding-left: 10px;
      padding-right: 10px;
      width: 100% !important;
    }
  }
}
.add-contact-type-popup {
  .el-dialog {
    width: 380px !important;
    @media (max-width: 400px) {
      width: 300px !important;
    }
  }
}
.save-contact-button {
  @media (max-width: 345px) {
    margin-bottom: 10px;
    margin-right: -8px !important;
  }
}
.action-button-wrapper {
  .action-button {
    @media (max-width: 500px) {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
}
.contact-types-card {
  height: 100%;
  width: 100% !important;
  background-color: white;
  box-shadow: 0px 0px 10px grey;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.contact-types-container {
  // display: flex;
  flex-direction: row;
  // justify-content: space-between; /* or any other value, e.g. space-around, space-evenly */
  gap: 20px; /* or any other value */
  padding-top: 10px;
  padding-left: 10px;
}
.item {
  margin-right: 20px;
  margin-left: 40px;
}
.for-name{
  display: flex;
  position: relative;
  top:20px;
}
.el-button--success{
  color:var(--primary-contrast-color);
  background-color: var(--primary-color);
  border-color:var(--primary-color)
}
.el-button--success:disabled{
  color:var(--primary-contrast-color);
  background-color: var(--lighter-background);
  border-color:var(--primary-color)
}
.el-button--success.is-disabled:hover,.el-button--success:hover {
  color:var(--primary-contrast-color);
  background-color: var(--lighter-background);
  border-color:var(--primary-color)
}
</style>
